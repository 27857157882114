import React, { FunctionComponent } from "react";
import {
  GenericErrorWrapper,
  GenericErrorContent,
  ErrorPlanet,
  ErrorText
} from "../StyledComponents";
import { FormattedMessage } from "react-intl";

interface IProps {
  hasInternalError?: boolean;
  apiErrorId?: string | null;
  children?: React.ReactNode;
}
const ErrorMessage: FunctionComponent<IProps> = ({
  hasInternalError = false,
  apiErrorId,
  children
}) => {
  return (
    <GenericErrorWrapper>
      {children}
      <GenericErrorContent>
        <ErrorPlanet />
        {hasInternalError ? (
          <ErrorText>
            <h1>
              <FormattedMessage
                id="GenericError.internalError.text.heading"
                defaultMessage="Sivua ei löydy"
              />
            </h1>
            <p>
              <FormattedMessage
                id={apiErrorId ?? "GenericError.internalError.text.explanation"}
                defaultMessage="Tämä sivu on saattanut poistua tai olla väliaikaisesti pois käytöstä."
              />
            </p>
          </ErrorText>
        ) : (
          <ErrorText>
            <h1>
              <FormattedMessage
                id="GenericError.general.text.heading"
                defaultMessage="Sivua ei löydy"
              />
            </h1>
            <p>
              <FormattedMessage
                id="GenericError.general.text.explanation"
                defaultMessage="Tämä sivu on saattanut poistua tai olla väliaikaisesti pois käytöstä."
              />
            </p>
          </ErrorText>
        )}
      </GenericErrorContent>
    </GenericErrorWrapper>
  );
};

export default ErrorMessage;
