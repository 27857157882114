import Receipt from "../../routes/receipt";

export const RECEIPT_ANIMATION_PLAY_TIME = 1000 * 60 * 30; // 30 minutes

export function isTransactionRecent(transaction: Receipt): boolean {
  const transactionCreatedTime = new Date(transaction.iat).getTime();
  const currentTime = new Date().getTime();
  const differenceInTime = currentTime - transactionCreatedTime;
  
  return differenceInTime <= RECEIPT_ANIMATION_PLAY_TIME;
}
