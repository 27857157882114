import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import jwt, { JwtPayload } from "jsonwebtoken";
import { useIntl } from "react-intl";
import config from "../../config";
import { centsToEuros } from "../../utils/amount/toEuros";
import { isTransactionRecent } from "../../utils/transaction";
import { formatDateToFinnishLocale } from "../../utils/formatDate";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import QRCode from "react-qr-code";
import Rive, { Fit, Layout } from "@rive-app/react-canvas";
import iconGiftcard from "../../assets/receipt/card_giftcard.svg";
import usedReceiptCover from "../../assets/receipt/usedReceiptCover.png";
import Animation from "../../assets/receipt/smartumpay_receipt_animations.riv";
import AppLayout from "../../components/Layout/AppLayout";
import CheckIcon from "@mui/icons-material/Check";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    maxWidth: "1512px",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
  },
  container: {
    display: "flex",
    maxWidth: "327px",
    padding: theme.spacing(0),
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(2),
    width: "100%",
    boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.12)",
  },
  cardContainer: {
    padding: theme.spacing(6),
  },
  amount: {
    fontWeight: "bold",
    color: "#CC4928",
    fontSize: "40px",
    textAlign: "center",
  },
  verifyButton: {
    borderColor: "#CC4928",
    color: "#CC4928",
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "0.8px",
  },
  buttonVerified: {
    color: "red",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
  },
  disclaimer: {
    textAlign: "left",
    maxWidth: "327px",
  },
  beneficiaryDetailsInfo: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  beneficiaryDetails: {
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "1.28px",
  },
  coverImage: {
    width: "100%",
    height: "88px",
    position: "relative",
    alignSelf: "stretch",
    borderRadius: "3px 3px 0px 0px",
    objectFit: "cover",
    objectPosition: "center",
  },
  qrCode: {
    textAlign: "center",
    fontWeight: 600,
  },
  paymentTypeGiftcardWrapper: {
    backgroundColor: "#F0E4D9",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4, 8),
    display: "flex",
    justifyContent: "center",
    letterSpacing: "1.28px",
  },
  paymentTypeGiftcard: {
    fontWeight: 600,
    fontSize: "16px",
  },
}));

interface Receipt extends JwtPayload {
  b2c: boolean;
  benefit: string;
  iat: number;
  venue_address: {
    line1: string;
    line2: string;
    code: string;
    city: string;
  };
  venue_name: string;
  verification_url: string;
  amount: number;
  transaction_id: string;
  payer_name?: string;
}

const Receipt = () => {
  const [searchParams] = useSearchParams();
  const { classes } = useStyles();
  const intl = useIntl();

  const [receipt, setReceipt] = useState<Receipt | null>(null);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const isGiftCard = receipt?.b2c;
  const navigate = useNavigate();

  const checkVerificationStatus = useCallback(() => {
    const transactionId = receipt?.transaction_id;
    const storedTransactionId = localStorage.getItem("verifiedTransactionId");
    if (
      transactionId &&
      storedTransactionId &&
      storedTransactionId === transactionId
    ) {
      setIsVerified(true);
    }
  }, [receipt]);

  const validateJwtToken = useCallback(() => {
    const jwtToken = searchParams.get("jwt");
    if (!jwtToken) {
      navigate("/error?type=receiptDownloadFailed");
      return;
    }
    try {
      const receiptInfo = jwt.verify(jwtToken, config.PUBLIC_KEY) as Receipt;
      setReceipt(receiptInfo);
    } catch (err) {
      navigate("/error?type=receiptDownloadFailed");
    }
  }, [searchParams, navigate]);

  useEffect(() => {
    validateJwtToken();
    checkVerificationStatus();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchParams]);

  useEffect(() => {
    checkVerificationStatus();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [receipt]);

  const markAsChecked = () => {
    if (receipt) {
      localStorage.setItem("verifiedTransactionId", receipt.transaction_id);
      setIsVerified(true);
    }
  };

  const renderReceiptCoverImage = () => (
    <Grid className={classes.coverImage}>
      {receipt && isTransactionRecent(receipt) && !isVerified ? (
        <Rive
          src={Animation}
          style={{ width: "100%", height: "88px" }}
          layout={
            new Layout({
              fit: Fit.Cover,
            })
          }
        />
      ) : (
        <img
          src={usedReceiptCover}
          alt="Used Receipt Cover"
          className={classes.coverImage}
        />
      )}
    </Grid>
  );

  const renderGiftCardInfo = () => (
    <Box
      display="inline-flex"
      alignItems="center"
      className={classes.paymentTypeGiftcardWrapper}
    >
      <img
        src={iconGiftcard}
        alt="Giftcard Icon"
        style={{ marginRight: "8px" }}
      />
      <Typography variant="body2" className={classes.paymentTypeGiftcard}>
        {intl
          .formatMessage({
            id: "Receipt.giftcardPayment",
          })
          .toUpperCase()}
      </Typography>
    </Box>
  );

  const renderReceiptInfo = () => (
    <Card className={classes.card}>
      <Grid className={classes.cardContainer}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Typography
            variant="h6"
            style={{ letterSpacing: "-0.5px", textAlign: "center" }}
          >
            {formatDateToFinnishLocale(new Date(receipt?.iat || ""))}
          </Typography>
          <Typography
            variant="h6"
            style={{
              fontSize: "12px",
              letterSpacing: "-0.5px",
              textAlign: "center",
            }}
          >
            {receipt?.transaction_id}
          </Typography>
        </Grid>
        <Box mt={8} />
        {!isGiftCard && (
          <>
            <Grid container justifyContent="center" alignItems="center">
              <Typography
                variant="body2"
                className={classes.beneficiaryDetailsInfo}
              >
                {intl.formatMessage({
                  id: "Receipt.paymentType",
                })}
              </Typography>
              <Typography
                variant="body2"
                className={classes.beneficiaryDetails}
              >
                {intl
                  .formatMessage({ id: `benefits.${receipt?.benefit}` })
                  .toUpperCase()}
              </Typography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Typography
                variant="body2"
                className={classes.beneficiaryDetailsInfo}
              >
                {intl.formatMessage({
                  id: "Receipt.payer",
                })}
                :
              </Typography>
              <Typography
                variant="body2"
                className={classes.beneficiaryDetails}
              >
                {receipt?.payer_name?.toUpperCase()}
              </Typography>
            </Grid>
            <Box mt={6} />
          </>
        )}

        {isGiftCard && (
          <>
            {renderGiftCardInfo()}
            <Box mt={8} />
          </>
        )}
        <Typography
          variant="h4"
          style={{ fontWeight: 600, textAlign: "center" }}
        >
          {receipt?.venue_name}
        </Typography>
        <Box mt={2} />
        <Typography variant="body2" style={{ textAlign: "center" }}>
          {receipt?.venue_address.line1}
          {receipt?.venue_address.line2
            ? ` ${receipt?.venue_address.line2},`
            : ", "}
          {receipt?.venue_address.code} {receipt?.venue_address.city}
        </Typography>
        <Box mt={8} />
        <Typography variant="h4" className={classes.amount}>
          {centsToEuros(receipt?.amount || 0)}
        </Typography>
        <Box mt={8} />
        <Divider />
        <Box mt={6} style={{ display: "flex", justifyContent: "center" }}>
          {renderVerificationButton()}
        </Box>
        <Box mt={6} />
        <Typography variant="body2" className={classes.qrCode}>
          {intl.formatMessage({
            id: "Receipt.qrCode",
          })}
        </Typography>
        <Box mt={4} style={{ display: "flex", justifyContent: "center" }}>
          <QRCode value={receipt?.verification_url || ""} size={164} />
        </Box>
        <Box mt={4} />
      </Grid>
    </Card>
  );

  const renderVerificationButton = () =>
    !isVerified && receipt && isTransactionRecent(receipt) ? (
      <Button
        variant="outlined"
        className={classes.verifyButton}
        onClick={markAsChecked}
      >
        {intl.formatMessage({
          id: "Receipt.verificationButtonVerify",
        })}
      </Button>
    ) : (
      <Typography variant="h6" className={classes.buttonVerified}>
        {intl.formatMessage({
          id: "Receipt.verificationVerified",
        })}
        <CheckIcon fontSize="small" style={{ marginLeft: "8px" }} />
      </Typography>
    );

  return (
    receipt && (
      <AppLayout useGrayBackground={true}>
        <Container className={classes.rootContainer}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
          >
            <Box mt={8} />
            <Typography variant="h1">
              {intl.formatMessage({
                id: "Receipt.thankYouTitle",
              })}
            </Typography>
            <Box mt={8} />
            <Typography variant="h3">
              {intl.formatMessage({
                id: "Receipt.receiptTitle",
              })}
            </Typography>
            <Box mt={4} />
            <Typography variant="subtitle1">
              {intl.formatMessage({
                id: "Receipt.description",
              })}
            </Typography>
            <Box mt={8} />
            {renderReceiptCoverImage()}
            {renderReceiptInfo()}
            <Box mt={8}>
              <Typography variant="body2" className={classes.disclaimer}>
                {intl.formatMessage({
                  id: "Receipt.receiptDisclaimer",
                })}
              </Typography>
            </Box>
            <Box mt={8} />
          </Grid>
        </Container>
      </AppLayout>
    )
  );
};

export default Receipt;
