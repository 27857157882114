import styled, { css, keyframes } from "styled-components";
import { animated } from "react-spring";

export const theme = {
  color: {
    white: "#ffffff",
    grey: "#9d9e9e",
    palegrey: "#ededed",
    orangered: "#fa5a32",
    red: "#e60a05",
    almostblack: "#14191a",
    lightgrey: "#c7c7c7",
    "almostblack-10": "rgba(20,25, 26, 0.1)",
    brick: "#ad3e23",
  },
};

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 45%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @media ${device.tablet} {
    width: 100%;
    height: 104px;
  }
`;

export const AppHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SmartumLogo = styled.img`
  width: 89.6px;
  height: 14px;
  object-fit: contain;
  margin-left: 5rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  @media ${device.tablet} {
    margin-left: 1rem;
    margin-top: 2rem;
  }
`;

interface LangProps {
  hasGenericError: boolean;
  isVenueSearch?: boolean;
  theme: {
    color: {
      [key: string]: string;
    };
  };
}

export const LanguageSelect = styled.select<LangProps>`
  height: 44px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-right: 5rem;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.67;
  text-align: right;
  padding-left: 10px;
  padding-right: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  ${(props: LangProps) =>
    props.hasGenericError
      ? css`
          color: ${(props) => props.theme.color.white};
          background: url("arrow-down-white.svg") no-repeat 90% 50%;
        `
      : css`
          color: ${(props) => props.theme.color.almostblack};
          background: url("arrow-down.svg") no-repeat 90% 50%;
        `}

  border: none;
  & svg {
    fill: black;
  }
  &:focus {
    outline: 0;
  }
  &::-ms-expand {
    display: none;
  }
  @media ${device.tablet} {
    margin-right: 0.5rem;
    margin-top: 1rem;
    ${(props) =>
      !props.isVenueSearch &&
      css`
        color: ${(props) => props.theme.color.white};
        background: url("arrow-down-white.svg") no-repeat 90% 50%;
      `}
  }
`;

export const LanguageOption = styled.option`
  width: 50px;
  height: 18px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.29;
  color: ${(props) => props.theme.color.almostblack};
`;

export const AppContent = styled.div`
  padding-left: 50%;
  padding-top: 4rem;
  max-height: 65%;
  overflow-y: scroll;

  @media ${device.tablet} {
    overflow: visible;
    max-height: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    padding: 0;
  }
`;

export const VenueWrapper = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 0px;

  @media ${device.tablet} {
    align-items: flex-start;
  }
  @media ${device.mobileM} {
    width: 320px;
    align-items: flex-start;
  }

  @media ${device.mobileS} {
    width: 300px;
  }
`;

export const VenueName = styled.span`
  width: 100%;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.color.brick};
  align-self: flex-start;
`;

export const VenueInfo = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const ProductImage = styled.img`
  height: 88px;
  min-width: 88px;
  margin-right: 16px;
  border-radius: 4px;
`;

export const Detail = styled.span`
  width: 218px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.color.almostblack};

  @media ${device.mobileM} {
    margin-top: 5px;
    font-size: 12px;
  }
`;
export const Price = styled.span`
  display: block;
  width: 91px;
  height: 30px;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.35;
  color: ${(props) => props.theme.color.almostblack};
  @media ${device.mobileM} {
    font-size: 22px;
  }
`;
export const Description = styled.span`
  width: 200px;
  height: 32px;
  font-size: 12px;
  line-height: 1.33;
  color: ${(props) => props.theme.color.grey};
  @media ${device.mobileM} {
    font-size: 10px;
  }
`;

export const AccountWrapper = styled.div`
  width: 360px;
  margin-top: 3rem;
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media ${device.mobileM} {
    width: 320px;
    padding-left: 0;
  }
  @media ${device.mobileS} {
    width: 300px;
    padding-left: 0;
  }
`;
export const Title = styled.span`
  margin-bottom: 8px;
  display: block;
  height: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.67;
  color: ${(props) => props.theme.color.almostblack};
`;

export const Subtitle = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 1.33;
  color: #9d9e9e;
  margin-bottom: 8px;
`;

export const NoUsableAccountText = styled.span`
  width: 103px;
  height: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: ${(props) => props.theme.color.brick};
`;

export const NavigationInstruction = styled.span`
  display: inline-block;
  margin: 16px 0px 0px;
  font-size: 12px;
  line-height: 1.33;
  color: #9d9e9e;
  text-align: center;
`;

interface ButtonProps {
  hasColor: boolean;
  theme: {
    color: {
      [key: string]: string;
    };
  };
}

export const Button = styled.button`
  width: 360px;
  height: 40px;
  background-color: ${(props: ButtonProps) =>
    props.hasColor ? props.theme.color.orangered : props.theme.color.palegrey};
  box-shadow: ${(props: ButtonProps) =>
    props.hasColor ? "0 4px 12px 0 rgba(250, 90, 50, 0.4)" : "none"};
  border: none;
  border-radius: 5px;
  margin: 16px 0px 0px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:focus {
    outline: 0;
  }
  @media ${device.mobileM} {
    width: 320px;
  }
  @media ${device.mobileS} {
    width: 300px;
  }
`;

export const ButtonText = styled.span`
  width: 312px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  line-height: 1.5;
  text-align: center;
  color: ${(props) => props.theme.color.white};
`;

interface FrameProps {
  checked: boolean;
  theme: {
    color: {
      orangered: string;
      palegrey: string;
    };
  };
}
export const MainFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 ${(props) => props.theme.color["almostblack-10"]};
  background-color: ${(props) => props.theme.color.white};
  margin-bottom: 8px;
  cursor: pointer;
  border: ${(props: FrameProps) =>
    props.checked
      ? `1px solid ${props.theme.color.orangered}`
      : `1px solid ${props.theme.color.palegrey}`};
  @media ${device.mobileM} {
    max-width: 320px;
  }
  @media ${device.mobileS} {
    max-width: 300px;
  }
`;

export const InnerFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Checker = styled.div`
  width: 16px;
  height: 16px;
  box-shadow: inset 0 1px 4px 0 rgba(20, 25, 26, 0.2);
  border-radius: 10px;
  background-color: ${(props) => props.theme.color.white};
  margin: 20px;
`;

export const InnerChecker = styled.div<{ checked?: boolean }>`
  display: ${(props) => (props.checked ? "block" : "none")};
  width: 8px;
  height: 8px;
  margin: 4px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(20, 25, 26, 0.2);
  background-color: ${(props) => props.theme.color.orangered};
`;

export const BenefitName = styled.span`
  width: 154px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.color.almostblack};
`;
export const EmployerName = styled.span`
  width: 68px;
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
  color: ${(props) => props.theme.color.almostblack};
`;

export const ExpirationDate = styled.span`
  width: 68px;
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
  color: ${(props) => props.theme.color.almostblack};
`;

export const Balance = styled.span`
  float: right;
  width: 62px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: right;
  color: ${(props) => props.theme.color.almostblack};
  margin: 16px 16px 16px 0;
`;

export const GreyFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.palegrey};
  margin-bottom: 8px;
  cursor: not-allowed;
  ${Checker} {
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.color.palegrey};
  }
  ${BenefitName}, ${EmployerName}, ${Balance} {
    color: ${(props) => props.theme.color.grey};
  }
  @media ${device.mobileM} {
    max-width: 320px;
  }
  @media ${device.mobileS} {
    max-width: 300px;
  }
`;

export const LoadingFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 360px;
  height: 56px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.palegrey};
  margin-bottom: 8px;
  overflow: hidden;
  @media ${device.mobileM} {
    max-width: 320px;
  }
  @media ${device.mobileS} {
    max-width: 300px;
  }
`;
const wave = keyframes`
  from { margin-left: -200px; }
  to { margin-left: 560px; }
`;
export const LoadingInnerFrame = styled.div`
  width: 200px;
  height: 56px;
  background-image: linear-gradient(to left, #fff, #f5f5f5 53%, #fff);
  animation: ${wave} 2s -0.125s infinite;
`;

export const StripeFormErrorMessage = styled.span`
  display: inline-block;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #ff381a;
`;

export const Modal = styled(animated.div)`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(20, 25, 26, 0.92);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const ModalContent = styled.div`
  display: flex;
  /* correctly center ModalContent on IE11 */
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: none;
  margin: auto;
  padding: 20px;
  border: none;
  width: 80%;
`;

interface IconProps {
  error: boolean;
  theme: {
    color: {
      red: string;
      orangered: string;
    };
  };
}

export const IconContainer = styled.div`
  width: 125px;
  height: 125px;
  border-radius: 62.5px;
  box-shadow: 0 8px 16px 0 ${(props) => props.theme.color["almostblack-10"]};
  background-color: ${(props: IconProps) =>
    props.error ? props.theme.color.red : props.theme.color.orangered};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const ModalConfirmText = styled.span`
  font-size: 26px;
  font-weight: 600;
  line-height: 1.35;
  text-align: center;
  color: ${(props) => props.theme.color.white};
  display: inline-block;
  margin-bottom: 16px;
`;

export const ModalRedirectText = styled.span`
  display: inline-block;
  width: 40%;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: ${(props) => props.theme.color.white};
  @media ${device.mobileL} {
    width: auto;
  }
`;

export const TimeWrapper = styled.div`
  text-align: center;
  width: 360px;
  margin-top: 16px;

  @media ${device.tablet} {
    width: 100vw;
  }
`;

export const TimeRemaining = styled.span`
  width: 103px;
  height: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: ${(props) => props.theme.color.brick};
`;

export const MainAppFooter = styled.div`
  @media ${device.tablet} {
    margin-top: 5rem;
    text-align: center;
    position: static;
    width: auto;
  }
  width: 50%;
  background-color: #fff;
  z-index: 1;
  position: absolute;
  left: 45%;
  bottom: 1rem;
  padding-left: 5%;
  padding-top: 1rem;
`;

export const ArrowLeft = styled.img`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
`;

export const FooterList = styled.ul`
  display: inline-block;
  padding-left: 4px;
`;

export const FooterListItem = styled.li`
  display: inline-block;
  margin-right: 32px;
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
  color: ${(props) => props.theme.color.lightgrey};
  &:first-child {
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const GenericErrorWrapper = styled.div`
  background-image: radial-gradient(circle at 21% 19%, #183147, #091d2e);
  background-size: cover;
  color: #fff;
  min-height: 100vh;
`;
export const GenericErrorContent = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0.5rem 0 0.5rem;
  @media (min-width: 600px) {
    margin: 100px 2rem 0 2rem;
    display: flex;
    flex-direction: row;
  }
`;

export const ErrorPlanet = styled.div`
  max-width: 200px;
  width: 100%;
  background: url("planet.svg") no-repeat center;
  height: 240px;
  background-size: contain;
  margin: 0 auto 30px auto;
  @media (min-width: 600px) {
    margin: 0;
    margin-right: 64px;
    max-width: 471px;
    height: 352px;
    flex-shrink: 2;
  }
`;

export const ErrorText = styled.div`
  max-width: 470px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  @media (min-width: 600px) {
    font-size: 16px;
    text-align: left;
  }
`;
