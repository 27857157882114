import React from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import NoConnection from "../../../assets/error/no_connection.png";
import { makeStyles } from 'tss-react/mui';

interface ErrorComponentProps {
  errorType?: string;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    maxWidth: "1040px",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    display: "flex",
    maxWidth: "375px",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "375px",
    },
  },
}));

export default function ErrorPage({ errorType }: ErrorComponentProps) {
  const { classes } = useStyles();
  const currentError = errorType || "pageNotFound";


  return (
    <Box className={classes.container}>
      <Box className={classes.content} p={6}>
        <Box mt={8} />
        <Typography variant="h1" gutterBottom>
          <FormattedMessage id={`ErrorPage.${currentError}.title`} />
        </Typography>
        <Box mt={8} />
        <Typography variant="body1" gutterBottom>
          <FormattedMessage id={`ErrorPage.${currentError}.message`} />
          {currentError === "pageNotFound" && (
            <a href="/">
              <FormattedMessage id={`ErrorPage.${currentError}.linkTitle`} />
            </a>
          )}
        </Typography>
        <Box mt={8} />
        <Box>
          <img className={classes.image} src={NoConnection} alt="Error image" />
        </Box>
        <Box mt={20} />
      </Box>
    </Box>
  );
}
