import intlTextsFI from "./fi";
import intlTextsEN from "./en";

type NestedMessages = {
  [key: string]: string | NestedMessages;
};

type Messages = {
  [key: string]: string;
};

function flattenMessages(nestedMessages: NestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: Messages, key) => {
    const value = nestedMessages[key];
    
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

const flattenedFI = flattenMessages(intlTextsFI);
const flattenedEN = flattenMessages(intlTextsEN);

export default {
  intlTextsFI: flattenedFI,
  intlTextsEN: flattenedEN,
};
