import React from "react";
import { useLocation } from "react-router-dom";
import AppLayout from "../../Layout/AppLayout";
import ErrorComponent from "../ErrorComponent";
import { errorTypeKey } from "../../../constants";

export default function ErrorPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const errorType = queryParams.get(errorTypeKey) || "pageNotFound";

  return (
    <AppLayout useGrayBackground={true}>
      <ErrorComponent errorType={errorType} />
    </AppLayout>
  );
}
