import { InfoType } from "../../components/withAppAuth";
import { IAccount } from "../../components/Pay";
import { LUNCH_LIMIT } from "../../constants";

export const shouldDisableAccount = (info: InfoType, a: IAccount): boolean => {
  let hasLunchUnderTaxLimit = false;

  if (info.benefit === "lunch") {
    if (a.allowance[info.benefit] < LUNCH_LIMIT.MIN) {
      hasLunchUnderTaxLimit = true;
    }
  }

  let isAccountDisabled;
  if (info.max_benefit_amount) {
    isAccountDisabled =
      a.allowance[info.benefit] === 0 ||
      (!info.stripe_account &&
        (a.allowance[info.benefit] < parseInt(info.amount) ||
          a.allowance[info.benefit] < parseInt(info.max_benefit_amount)));
  } else {
    isAccountDisabled =
      a.allowance[info.benefit] === 0 ||
      (!info.stripe_account &&
        a.allowance[info.benefit] < parseInt(info.amount));
  }
  return isAccountDisabled || hasLunchUnderTaxLimit;
};

export const calculateStripeChargeAmount = (
  amount: number,
  maxBenefitAmount: number | null | undefined,
  balance: number
): number => {
  let chargeAmount = amount > balance ? amount - balance : 0;
  if (maxBenefitAmount) {
    if (amount > maxBenefitAmount) {
      chargeAmount =
        maxBenefitAmount > balance
          ? amount - balance
          : amount - maxBenefitAmount;
    }
  }

  return chargeAmount;
};

export const getChargeAmounts = (info: InfoType, account: IAccount) => {
  let stripeChargeAmount = 0,
    saldoChargeAmount = 0,
    hasMinimumStripeCharge = false;
  const amount = parseInt(info.amount);
  const maxBenefitAmount = parseInt(info.max_benefit_amount || info.amount);
  if (account.id) {
    stripeChargeAmount = calculateStripeChargeAmount(
      amount,
      maxBenefitAmount,
      account.allowance[info.benefit]
    );
    saldoChargeAmount = amount - stripeChargeAmount;

    if (stripeChargeAmount < 50 && stripeChargeAmount > 0) {
      stripeChargeAmount = 50;
      saldoChargeAmount = amount - 50 > LUNCH_LIMIT.MIN ? amount - 50 : saldoChargeAmount;
      hasMinimumStripeCharge = true;
    }
  }
  return { stripeChargeAmount, saldoChargeAmount, hasMinimumStripeCharge };
};
