import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Checkbox,
  Button,
  Menu,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Close, ExpandMore } from "@mui/icons-material";
import { BENEFIT_OPTIONS } from "../../../constants";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles()((theme) => ({
  dropDownMenu: {
    marginTop: "10px",
  },
  dropDownContainer: {
    padding: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    width: "327px",
  },
  showResultsButton: {
    width: "327px",
    [theme.breakpoints.down("md")]: { width: "100%" },
  },
  menuItem: {
    padding: theme.spacing(1, 6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 2),
    },
    backgroundColor: theme.palette.background.default,
    width: "375px",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  headerTitle: {
    flexGrow: 1,
  },
  menuList: {
    paddingTop: "0px",
    paddingBottom: "0px",
    width: "100%",
  },
  dialogTitleConteiner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    padding: theme.spacing(4, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogHeading: {
    margin: theme.spacing(4),
  },
  dialogTitleRoot: {
    padding: "0",
  },
}));

type BenefitsDropdownProps = {
  handleShowResults: (benefits: string[]) => void;
  selectedBenefits: string[];
};

export default function BenefitsDropdown(
  props: Readonly<BenefitsDropdownProps>
) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const intl = useIntl();

  const [benefits, setBenefits] = useState<string[]>([]);
  const { handleShowResults, selectedBenefits } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    setBenefits(selectedBenefits);
  }, [selectedBenefits]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      setOpenDialog(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDialog(false);
    handleShowResults(benefits);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === "all") {
      const isAllSelected = benefits.includes("all");
      setBenefits(
        isAllSelected ? [] : BENEFIT_OPTIONS.map((option) => option.value)
      );
    } else {
      toggleBenefit(value);
    }
  };

  const toggleBenefit = (value: string) => {
    const newBenefits = benefits.includes(value)
      ? benefits.filter((b) => b !== value)
      : [...benefits, value];

    setBenefits(newBenefits);
  };

  const renderBenefitOptions = () => {
    return BENEFIT_OPTIONS.map((option) => (
      <MenuItem key={option.value} className={classes.menuItem}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={
                benefits.includes("all") || benefits.includes(option.value)
              }
              onChange={handleChange}
              value={option.value}
            />
          }
          label={
            option.value === "all"
              ? intl.formatMessage({ id: "VenueSearch.allResults" })
              : intl.formatMessage({ id: `benefits.${option.value}` })
          }
        />
      </MenuItem>
    ));
  };

  const renderShowResultsButton = () => {
    return (
      <Button
        className={classes.showResultsButton}
        onClick={handleClose}
        fullWidth
      >
        <FormattedMessage id="VenueSearch.showResults" />
      </Button>
    );
  };

  const displayText =
    benefits.length > 0
      ? benefits.includes("all")
        ? intl.formatMessage({
            id: "VenueSearch.allResults",
          })
        : benefits
            .map((b) => intl.formatMessage({ id: `benefits.${b}` }))
            .join(", ")
      : intl.formatMessage({
          id: "VenueSearch.filterResults",
        });

  return (
    <Box>
      <Button variant="outlined" endIcon={<ExpandMore />} onClick={handleClick}>
        {displayText}
      </Button>

      {/* Dialog for mobile */}
      <Dialog
        fullScreen={isMobile}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="mobile-benefits-dialog"
      >
        <DialogTitle className={classes.dialogTitleRoot}>
          <Box className={classes.dialogTitleConteiner}>
            <IconButton onClick={handleClose} color="primary">
              <Close />
            </IconButton>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.dialogHeading}
            >
              <FormattedMessage id="VenueSearch.benefitTypes" />
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {renderBenefitOptions()}
          <Box mt={10} />
          {renderShowResultsButton()}
        </DialogContent>
      </Dialog>

      {/* Menu for desktop */}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.dropDownMenu}
        classes={{ list: classes.menuList }}
      >
        <Box className={classes.dropDownContainer}>
          {renderBenefitOptions()}
          <Box mt={5} />
          {renderShowResultsButton()}
        </Box>
      </Menu>
    </Box>
  );
}
