import React from "react";
import { FormattedMessage } from "react-intl";
import capitalize from "lodash/capitalize";
import size from "lodash/size";
import {
  MainFrame,
  InnerFrame,
  GreyFrame,
  Checker,
  BenefitName,
  EmployerName,
  ExpirationDate,
  Balance,
  InnerChecker
} from "../../../StyledComponents";
import formatCurrency from "../../../../utils/formatCurrency";
import { IAccount } from "../../../Pay";
import { BenefitType } from '../../../withAppAuth'

export default function Frame({
  disabled,
  checked,
  handleClick,
  account: a,
  targetBenefitType,
}: {
  disabled: boolean;
  checked: boolean;
  handleClick: {(id: string): any};
  account: IAccount;
  targetBenefitType: BenefitType;
}) {
  function renderBenefits() {
    if (a.type === 'Plus') {
      return (
        <FormattedMessage
          id="benefits.Plus"
          defaultMessage="SmartumPlus"
        />
      );
    } else if (size(a.benefits) > 1) {
      return (
        <FormattedMessage
          id="benefits.exerciseAndCulture"
          defaultMessage="Liikunta ja kulttuuri"
        />
      );
    }
    return (
      <FormattedMessage
        id={`benefits.${a.benefits[0]}`}
        defaultMessage={`${capitalize(a.benefits[0])}`}
      />
    );
  }

  if (disabled) {
    return (
      <GreyFrame>
        <InnerFrame>
          <Checker />
          <div>
            <BenefitName>{renderBenefits()}</BenefitName>
            <br />
            <EmployerName>{a.employer.name}</EmployerName>
            {a.expiration !== undefined ?
              <>
              <br/>
              <ExpirationDate>{a.expiration}</ExpirationDate>
              </>
              : <></>
            }
          </div>
        </InnerFrame>
        <Balance>{`${formatCurrency(a.allowance[targetBenefitType])}€`}</Balance>
      </GreyFrame>
    );
  }

  return (
    <MainFrame checked={checked} onClick={() => handleClick(a.id)}>
      <InnerFrame>
        <Checker>
          <InnerChecker checked={checked} />
        </Checker>
        <div>
          <BenefitName>{renderBenefits()}</BenefitName>
          <br />
          <EmployerName>{a.employer.name}</EmployerName>
          {a.expiration !== undefined ?
            <>
            <br/>
            <ExpirationDate>{a.expiration}</ExpirationDate>
            </>
            : <></>
          }
        </div>
      </InnerFrame>
      <Balance>{`${formatCurrency(a.allowance[targetBenefitType])}€`}</Balance>
    </MainFrame>
  );
}
