import React from "react";
import {
  StripeCardElementChangeEvent,
  StripeCardElement
} from "@stripe/stripe-js";
import { FormattedMessage } from "react-intl";
import {
  Title,
  StripeFormErrorMessage,
  Subtitle
} from "../../../StyledComponents";
import formatCurrency from "../../../../utils/formatCurrency";

interface IProps {
  setStripeFormError: {(setError: boolean): any;};
  card: StripeCardElement | null;
  stripeChargeAmount: number;
  hasMinimumStripeCharge: boolean;
}

const StripeSection: React.FC<IProps> = ({
  setStripeFormError,
  card,
  stripeChargeAmount,
  hasMinimumStripeCharge
}) => {
  const [error, setError] = React.useState<
    StripeCardElementChangeEvent["error"]
  >(undefined);

  React.useEffect(
    () => {
      if (card) {
        setError(undefined);
        card.on("change", handleChange);
        card.on("ready", handleReady);
        card.mount("#card-element");
      }
    },
    [card]
  );

  const handleChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setError(event.error);
      setStripeFormError(true);
    } else {
      setStripeFormError(false);
      setError(undefined);
    }
  };

  const handleReady = () => {
    if (card) card.focus();
  };

  return (
    <div style={{ marginTop: "32px" }}>
      <Title style={{ marginBottom: 0 }}>
        <FormattedMessage
          id="StripeSection.title.payAmountWithCard"
          values={{
            price: formatCurrency(stripeChargeAmount)
          }}
        />
      </Title>
      {hasMinimumStripeCharge && (
        <Subtitle>
          <FormattedMessage id="StripeSection.subtitle.minimumCharge" />
        </Subtitle>
      )}
      <div style={{ marginBottom: error ? "0px" : "16px" }}>
        <div id="card-element" />
        {error && (
          <StripeFormErrorMessage>{error.message}</StripeFormErrorMessage>
        )}
      </div>
    </div>
  );
};

export default StripeSection;
