import config from "../../config";
import { IAccount } from "../../components/Pay";
import { InfoType } from "../../components/withAppAuth";
import { Benefit, Venue } from "../../types/venue";
import { disableSelectKey, RECEIPT_SUCCESS_URL, PAYMENT_CANCEL_URL} from "../../constants";
import axios from "axios";
import { parseToGiftcardAccount } from "../account/parseToGiftcardAccount";
import { GiftcardPayment } from "../../types/payment";

const {
  REACT_APP_MAKE_PAYMENT_API,
  REACT_APP_JWT_HEADER_KEY,
  REACT_APP_GET_ACCOUNTS_API,
  REACT_APP_GET_VENUE_API,
  REACT_APP_CREATE_PAYMENT_INTENT_API,
  REACT_APP_JWT_RECEIPT_HEADER_KEY,
} = config;

export const createPaymentIntent = async (
  stripeAccount: string,
  stripeChargeAmount: number,
  accessToken: string | null | undefined
) => {
  const data = {
    account: stripeAccount,
    amount: stripeChargeAmount,
  };
  const requestInit: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    REACT_APP_CREATE_PAYMENT_INTENT_API,
    requestInit
  );
  const res = await response.json();
  if (response.status === 200) {
    return { clientSecret: res.data.client_secret };
  } else {
    throw res.error;
  }
};

export const makePayment = async (
  account: IAccount,
  info: InfoType,
  accessToken: string | null | undefined,
  saldoChargeAmount: number,
  stripePaymentIntentId: string
) => {
  const data = mapDataFromProps(
    account,
    info,
    stripePaymentIntentId,
    saldoChargeAmount
  );
  const requestInit: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  };
  const result = await fetch(REACT_APP_MAKE_PAYMENT_API, requestInit);
  const res = await result.json();
  const jwt = result.headers.get(REACT_APP_JWT_HEADER_KEY) || "";
  const receiptToken =
    result.headers.get(REACT_APP_JWT_RECEIPT_HEADER_KEY) || "";
  return { res, jwt, receiptToken };
};

const mapDataFromProps = (
  account: IAccount,
  info: InfoType,
  stripePaymentIntentId: string,
  saldoChargeAmount: number
) => ({
  from: account.id,
  to: info.venue_id,
  amount: saldoChargeAmount,
  benefit: info.benefit,
  description: info.product_description || "",
  nonce: info.nonce,
  stripe_payment_intent_id: stripePaymentIntentId,
  idempotency_id: info.idempotency_id,
});

async function fetchWithHeaders(url: string, customHeaders = {}) {
  const headers = {
    "Content-Type": "application/json",
    ...customHeaders,
  };

  try {
    const response = await fetch(url, { headers });
    const resJson = await response.json();

    if (response.ok) {
      return resJson;
    } else {
      return Promise.reject(resJson);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getAccounts = async (accessToken: string | null) => {
  return fetchWithHeaders(REACT_APP_GET_ACCOUNTS_API, {
    Authorization: `Bearer ${accessToken}`,
  });
};

export const getVenue = async (venue_id: string) => {
  return fetchWithHeaders(`${REACT_APP_GET_VENUE_API}/${venue_id}`);
};

export const getVenues = async (query: string, benefits: string, limit: number, startingAfter: string) => {
  const url = new URL(REACT_APP_GET_VENUE_API);
  const params: Record<string, string> = {
    q: query,
    benefits: benefits || "",
    limit: limit.toString(),
    starting_after: startingAfter,
  };

  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

  return fetchWithHeaders(url.toString());
};

export const getExpirations = async (
  accessToken: string | null,
  accountId: string
) => {
  return fetchWithHeaders(
    `${REACT_APP_GET_ACCOUNTS_API}/${accountId}/expirations`,
    {
      Authorization: `Bearer ${accessToken}`,
    }
  );
};

interface PostRequestOptions {
  url: string;
  payload: object;
  headers?: object;
}

interface PostResponse {
  response: any | null;
  error: string | null;
  headers?: any;
}

const postRequestWithHeaders = async ({
  url,
  payload,
  headers,
}: PostRequestOptions): Promise<PostResponse> => {
  try {
    const response = await axios.post(url, payload, { headers });
    return { response: response.data, headers: response.headers, error: null };
  } catch (error: any) {
    let returnError = null;
    if (error.response) {
      const code = error.response.data?.error?.code;
      returnError = code;
    } else if (error.request) {
      returnError = "generic";
    }
    return { response: null, error: returnError || "generic" };
  }
};

interface CheckoutPayload {
  venue: string;
  amount: number;
  benefit: string;
  product_name: string;
  success_url: string;
  cancel_url: string;
}

export const getCheckoutRedirectUrl = async (venue: Venue, amount: number, benefit: Benefit) => {

  const payload: CheckoutPayload = {
    venue: venue.id,
    amount: amount,
    benefit: benefit,
    product_name: "Payment",
    success_url: RECEIPT_SUCCESS_URL,
    cancel_url: PAYMENT_CANCEL_URL,
  };

  const { response, error } = await postRequestWithHeaders({
    url: config.REACT_APP_ISSUER + "/checkout",
    payload: payload,
    headers: {
      "Smartum-Version": "2020-04-02",
    },
  });

  if (!error) {
    const urlReturnedFromAPI = response?.data?.url;
    const urlParameters = `${urlReturnedFromAPI}&${disableSelectKey}=1`;
    const baseUrl = window.location.origin;
    return { url: `${baseUrl}/?${urlParameters.split("?")[1]}` };
  }
  return { error };
};

const GIFTCARD_ACCOUNT = "/giftcard/b2c/get-by-claim-code";

export const getGiftcardAccountByClaimCode = async (claimCode: string) => {
  const { response, error } = await postRequestWithHeaders({
    url: `${config.REACT_APP_ISSUER}${GIFTCARD_ACCOUNT}`,
    payload: { claim_code: claimCode },
  });

  if (!error) {
    const { account, token } = response.data;
    const parsedAccount = parseToGiftcardAccount(account, token);
    return { account: parsedAccount };
  }
  return { error };
};

const GIFTCARD_PAYMENT = "/giftcard/b2c/payments";

export const makeGiftcardPayment = async (giftcardPayment: GiftcardPayment) => {
  const { error, headers } = await postRequestWithHeaders({
    url: `${config.REACT_APP_ISSUER}${GIFTCARD_PAYMENT}`,
    payload: { ...giftcardPayment },
  });

  if (!error) {
    const jwtToken = headers[REACT_APP_JWT_HEADER_KEY];
    const receiptToken = headers[REACT_APP_JWT_RECEIPT_HEADER_KEY];
    return { jwtToken, receiptToken };
  }
  return { error };
};

const CONFIGURATION = "/configuration/smartum";

export const getLunchLimits = async () => {
  try {
    const { data } = await fetchWithHeaders(
      `${config.REACT_APP_ISSUER}${CONFIGURATION}`
    );
    return { constants: data.constants };
  } catch (error) {
    return { error };
  }
};
