import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { AccordionName, AmountError } from "../../../containers/PaymentSelect";
import { FormattedMessage } from "react-intl";
import {
  amountPattern,
  isValidAmount,
} from "../../../utils/validation/validateValue";

type AmountInputProps = {
  amount: string;
  setAmount: (value: string) => void;
  validateAmount: (value: string) => boolean;
  amountError: AmountError | null;
  expanded: boolean;
  handleAccordionChange: (
    panel: AccordionName
  ) => (event: React.ChangeEvent<object>, newExpanded: boolean) => void;
  disabled: boolean;
};

export default function AmountInput(props: Readonly<AmountInputProps>) {
  const {
    amount,
    setAmount,
    validateAmount,
    amountError,
    expanded,
    handleAccordionChange,
    disabled,
  } = props;

  const [missingAmount, setMissingAmount] = useState<boolean>(false);

  const onValidateAmountClick = () => {
    const isValid = validateAmount(amount);
    setMissingAmount(!isValid);
  };

  const handleAmountInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value;
    value = value.replace(".", ",");
    const validInput = isValidAmount(value);
    if (validInput) {
      setAmount(value || "");
    }
  };

  const hasInputError = amountError?.isLunchAmountErr || missingAmount;

  const formatAmountWithComma = (amount: string) => {
    const parsedAmount = parseFloat(amount.replace(",", "."));
    return isNaN(parsedAmount)
      ? "0,00"
      : parsedAmount.toFixed(2).replace(".", ",");
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionChange("amount")}
      square={false}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="amount"
        id="amount"
      >
        <Box>
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage id="AmountInput.amount" />
          </Typography>
          <Box mt={2} />
          {!expanded && (
            <Typography variant="h5">
              {amount && `${formatAmountWithComma(amount)} €`}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          component="form"
          onSubmit={(event) => {
            event.preventDefault();
            onValidateAmountClick();
          }}
        >
          <Typography
            variant="subtitle2"
            color={hasInputError ? "error" : "initial"}
          >
            <FormattedMessage id="AmountInput.enterAmount" />
          </Typography>
          <Box mt={2} />
          <FormControl error={!!hasInputError}>
            <OutlinedInput
              error={!!hasInputError}
              id="amount"
              type="text"
              inputProps={{
                min: 0,
                step: "any",
              }}
              className="formOutlinedInput"
              value={amount}
              onChange={handleAmountInputChange}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              disabled={disabled}
              onFocus={() => (amount === "0" ? setAmount("") : null)}
              onBlur={onValidateAmountClick}
            />
            {amountError?.isLunchAmountErr && (
              <FormHelperText id="component-error-text" className="">
                <FormattedMessage
                  id={amountError.key}
                  values={{ ...amountError.values }}
                />
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
