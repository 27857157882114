import { Benefit, GiftcardBenefitType } from "../types/venue";

export const GRANT_TYPE = {
  AUTHORIZATION_CODE: "authorization_code",
  REFRESH_TOKEN: "refresh_token",
};

export const venueIdKey = "venue_id";
export const venuePrefixKey = "ven_";
export const disableSelectKey = "disable_select";
export const successUrlKey = "success_url";
export const cancelUrlKey = "cancel_url";
export const errorTypeKey = "type";

export const INFOKEY = [
  "benefit",
  venueIdKey,
  "amount",
  "product_name",
  successUrlKey,
  cancelUrlKey,
];

export const LUNCH_LIMIT = {
  MIN: 800,
};

export const SMS_PAYMENT_PHONE_NUMBER = "+3584573960755";

export const RECEIPT_SUCCESS_URL = "/receipt";
export const PAYMENT_CANCEL_URL = "/error?type=paymentFailed";

export const giftcardBenefitTypes: GiftcardBenefitType[] = [
  "health",
  "exercise",
  "culture",
  "massage",
];

export const benefitPriorityOrder: Benefit[] = [
  "exercise",
  "culture",
  "massage",
  "health",
];

export const wellbeingGiftcard: GiftcardBenefitType[] = ["health", "massage"];

type BenefitOptions = {
  value: Benefit | "all";
};

export const BENEFIT_OPTIONS: BenefitOptions[] = [
  { value: "all" },
  { value: "lunch" },
  { value: "exercise" },
  { value: "culture" },
  { value: "commuter" },
  { value: "massage" },
  { value: "health" },
];
