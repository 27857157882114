import React, { PureComponent } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { Spring } from "react-spring";
import {
  Modal,
  ModalContent,
  ModalConfirmText,
  ModalRedirectText,
  IconContainer,
} from "../../StyledComponents";
import checkIcon from "../../../assets/check.svg";
import closeIcon from "../../../assets/close.svg";

class StyledModal extends PureComponent<
  {
    isOpen: boolean;
    error: { hasError: boolean; errorId: string };
    successUrl: string;
    cancelUrl: string;
  } & WrappedComponentProps,
  unknown
> {
  componentDidMount() {
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount() {
    document.body.style.overflow = "scroll";
  }
  render() {
    const {
      error: { hasError, errorId },
      successUrl,
      cancelUrl,
      intl,
    } = this.props;

    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ tension: 300, friction: 24 }}
      >
        {(props) => (
          <Modal style={props}>
            <Spring
              config={{ tension: 300, friction: 24 }}
              from={{ transform: "translate3d(0,20rem,0)", opacity: 0 }}
              to={{ transform: "translate3d(0,0,0)", opacity: 1 }}
            >
              {(props) => (
                <ModalContent style={props}>
                  {!hasError ? (
                    <>
                      <IconContainer error={hasError}>
                        <img src={checkIcon} width={72} height={72} />
                      </IconContainer>
                      <ModalConfirmText>
                        <FormattedMessage id="Modal.confirmMessage.success" />
                      </ModalConfirmText>
                      <ModalRedirectText>
                        <FormattedMessage
                          id="Modal.redirectMessage"
                          defaultMessage="Siirrytään takaisin sivulle {redirectUrl}"
                          values={{ redirectUrl: successUrl }}
                        />
                      </ModalRedirectText>
                    </>
                  ) : (
                    <>
                      <IconContainer error={hasError}>
                        <img src={closeIcon} width={72} height={72} />
                      </IconContainer>
                      <ModalConfirmText>
                        <FormattedMessage
                          id={
                            errorId === "unknown"
                              ? "Modal.confirmMessage.error.general"
                              : `Modal.confirmMessage.error.${errorId}`
                          }
                          defaultMessage={intl.formatMessage({
                            id: "Modal.confirmMessage.error.general",
                          })}
                        />
                      </ModalConfirmText>
                      <ModalRedirectText>
                        <FormattedMessage
                          id="Modal.redirectMessage"
                          defaultMessage="Siirrytään takaisin sivulle {redirectUrl}"
                          values={{ redirectUrl: cancelUrl }}
                        />
                      </ModalRedirectText>
                    </>
                  )}
                </ModalContent>
              )}
            </Spring>
          </Modal>
        )}
      </Spring>
    );
  }
}
export default injectIntl(StyledModal);
