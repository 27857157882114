import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import marker from "../../assets/marker.svg";
import Box from "@mui/material/Box";
import { makeStyles } from "tss-react/mui";

const pointerIcon = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  iconAnchor: [27, 46],
  iconSize: [54, 54],
});

type MapProps = {
  latitude: number;
  longitude: number;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    height: "144px",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      height: "240px",
    },
  },
}));

export default function VenueMap({ latitude, longitude }: MapProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <MapContainer
        center={[latitude, longitude]}
        zoom={15}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY2hyaXN0aWFuc29obGJlcmciLCJhIjoiY2prdXRkYXFwMGpqczNwcXFsc2YzMHQyYyJ9.FtojERB1GdUeql5ed69uuw" />
        <Marker position={[latitude, longitude]} icon={pointerIcon} />
      </MapContainer>
    </Box>
  );
}
