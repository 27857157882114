import { IntlShape } from "react-intl";
import { ApiResponseVenue, Venue } from "../../types/venue";
import {
  getBenefitAndGiftcardList,
  isAcceptingOnlyQrPayments,
  isGiftcardBenefitType,
} from "../benefit";

/**
 * Checks if the venue accepts mobile payments.
 *
 * @param venue - The venue object from the API response.
 * @returns True if the venue accepts mobile payments, false otherwise.
 */
export const venueAcceptsMobilePayment = (venue: ApiResponseVenue): boolean => {
  return venue.accepted_payment_methods.includes("mobile");
};

export const venuePaymentMethods = (venue: Venue, intl: IntlShape) => {
  const list = getBenefitAndGiftcardList(venue?.accepted_benefits);
  if (!list) {
    return [];
  }
  const translatedBenefits = list.benefits.map((benefit) =>
    intl.formatMessage(
      {
        id: "VenueItem.benefitName",
      },
      {
        benefit: intl.formatMessage({
          id: `benefits.${benefit}`,
        }),
      }
    )
  );

  const translatedGiftcards =
    venueAcceptsMobilePayment(venue) || isAcceptingOnlyQrPayments(venue)
      ? list.giftcards.map((giftcardType) =>
          intl.formatMessage({ id: `giftcards.${giftcardType}` })
        )
      : [];

  return [translatedBenefits, ...translatedGiftcards].flat();
};

// if venue accepted benefits contains giftcard benefit type -> giftcard payment is accepted
export const venueAcceptsGiftcardPayment = (venue: Venue) => {
  return (
    ((venueAcceptsMobilePayment(venue) || isAcceptingOnlyQrPayments(venue)) &&
      venue?.accepted_benefits?.some(isGiftcardBenefitType)) ||
    false
  );
};
