import { IntlShape } from "react-intl";
import { GiftcardAccount } from "../../types/account";
import { Benefit, GiftcardBenefitType, Venue } from "../../types/venue";
import { toCents } from "../amount/toCents";
import { venuePaymentMethods } from "../venue";

export const getGiftcardPaymentValidationError = (
  giftcardAccount: GiftcardAccount | null,
  amount: string
): null | string => {
  let errorKey = null;

  if (giftcardAccount && giftcardAccount.balance < toCents(amount)) {
    errorKey = "GiftcardInput.error.invalid_amount";
  }
  return errorKey;
};

export const getGiftcardValidationError = (
  giftcardAccount: GiftcardAccount | null,
  paymentBenefit: Benefit | null,
  venue: Venue | null,
  intl: IntlShape,
  setValidationErrorValues: (values: { values: string }) => void
): null | string => {
  if (!giftcardAccount || !venue) {
    return null;
  }

  if (giftcardAccount.expiresAt.getTime() < Date.now()) {
    return "GiftcardInput.error.expired";
  }

  const invalidBenefit = !giftcardAccount.benefits.some((benefit) =>
    venue.accepted_benefits?.includes(benefit)
  );

  if (invalidBenefit) {
    setValidationErrorValues({
      values: venuePaymentMethods(venue, intl).join(", "),
    });
    return "GiftcardInput.error.invalid_benefit";
  }
  // TODO: fix ugly type casting, because paymentBenefit can actually be any benefit type
  if (
    paymentBenefit &&
    !giftcardAccount.benefits.includes(paymentBenefit as GiftcardBenefitType)
  ) {
    return "GiftcardInput.error.invalid_partner_benefit";
  }
  return null;
};
