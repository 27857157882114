export const VALID_ISO_DATE_REGEXP = /^\d{4}-\d{2}-\d{2}/

export const isValidISO8601Date = (string: string) => {
    return VALID_ISO_DATE_REGEXP.test(string)
  }


export const formatISO8601DateToFinnishLocaleDateString = (strDate: string) => {

    if (!isValidISO8601Date(strDate)) {
        return undefined
    }

    const [year, month, day] = strDate.substring(0, 10).split('-')

    return `${day}.${month}.${year}`
}


export const formatDateToFinnishLocale = (date: Date | null): string => {
  if (date === null) {
    return '';
  }

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Helsinki', 
  };

  return new Intl.DateTimeFormat('fi-FI', options).format(date);
};