export default function getApiErrorTranslationId(error: {
  type: string;
  code?: string;
  message: string;
  param?: string;
}): string {
  const { message: msg } = error;
  switch (msg) {
    case (/format is invalid/i).exec(msg)?.input:
      return "venue_parameter_invalid";
    case (/venue does not exist/i).exec(msg)?.input:
      return "venue_not_found";
    case (/ID is invalid, must start with acct/i).exec(msg)?.input:
      return "account_id_invalid";
    case (/incompatible ID version/i).exec(msg)?.input:
      return "incompatible_account_id";
    case (/Account does not exist/i).exec(msg)?.input:
      return "account_not_found";
    default:
      return "general";
  }
}
