import { difference, isEmpty } from "lodash";
import qs from "qs";
import { INFOKEY } from "../../constants";

const hasCheckoutURLParams = (): boolean => {
  return isEmpty(difference(INFOKEY, Object.keys(checkoutInfoParams)));
};


const checkoutInfoParams = new Object(
  qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    parseArrays: false,
  })
);

export { hasCheckoutURLParams, checkoutInfoParams };