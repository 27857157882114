import React from "react";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Benefit } from "../../../types/venue";
import { FormattedMessage, useIntl } from "react-intl";
import { benefitToTranslationKey } from "../../../utils/benefit";

type BenefitSelectionProps = {
  benefits: Benefit[];
  selectedBenefit?: Benefit;
  handleBenefitSelection: (benefit: Benefit) => void;
};

const BenefitSelection: React.FC<BenefitSelectionProps> = ({
  benefits,
  selectedBenefit,
  handleBenefitSelection,
}) => {
  const intl = useIntl();

  const isDisabled = benefits.length <= 1;

  return (
    <Box mt={4}>
      <Typography variant="subtitle2" color="textSecondary">
        <FormattedMessage id="PaymentMethod.selectBenefitType" />
      </Typography>
      <Box mt={2} />
      {benefits.map((be) => (
        <Box key={be}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
            >
              <FormControlLabel
                id={be}
                control={<Radio disabled={isDisabled} color="primary" />}
                name="benefit"
                value={be}
                checked={selectedBenefit === be}
                onChange={() => handleBenefitSelection(be)}
                label={intl.formatMessage({
                  id: `benefits.${benefitToTranslationKey(be)}`,
                })}
                disabled={isDisabled}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      ))}
    </Box>
  );
};

export default BenefitSelection;
