// https://developer.mozilla.org/en-US/docs/Web/API/Storage/LocalStorage#Compatibility
export class CookieStorage {
  length: number;

  constructor() {
    this.length = 0;
  }

  getItem(key: string) {
    if (!key || !this.hasOwnPropertySet(key)) {
      return null;
    }
    return unescape(
      document.cookie.replace(
        new RegExp(
          `(?:^|.*;\\s*)${escape(key).replace(
            /[-.+*]/g,
            "\\$&",
          )}\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*`,
        ),
        "$1",
      ),
    );
  }

  key(index: number) {
    return unescape(
      document.cookie
        .replace(/\s*=(?:.(?!;))*$/, "")
        .split(/\s*=(?:[^;](?!;))*[^;]?;\s*/)[index],
    );
  }

  setItem(key: string, data: string) {
    if (!key) {
      return;
    }
    document.cookie = `${escape(key)}=${escape(
      data,
    )}; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/`;
    const match = document.cookie.match(/=/g);
    if (match) {
      this.length = match.length;
    }
  }

  removeItem(key: string) {
    if (!key || !this.hasOwnPropertySet(key)) {
      return;
    }
    document.cookie = `${escape(
      key,
    )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    this.length--;
  }

  hasOwnPropertySet(key: string) {
    return new RegExp(
      `(?:^|;\\s*)${escape(key).replace(/[-.+*]/g, "\\$&")}\\s*\\=`,
    ).test(document.cookie);
  }
}
