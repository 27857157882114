import React, { Component } from "react";

import AppHeader from "./Header";
import AppContent from "./Content";
import AppFooter from "./Footer";
import Loader from "./Loader";
import ErrorMessage from "./ErrorMessage";
import { Background } from "./StyledComponents";

import { getAccounts, getVenue } from "../utils/api";
import withAppAuth, { WithAppAuthTypes, BenefitType } from "./withAppAuth";
import { getExpirations } from "../utils/api/api";
import { formatISO8601DateToFinnishLocaleDateString } from "../utils/formatDate";
import getApiErrorTranslationId from "../utils/error/getApiErrorTranslationId";

export interface IAccount {
  balance: number;
  benefits: Array<BenefitType>;
  employer: {
    id: string;
    name: string;
  };
  allowance: Record<BenefitType, number>;
  id: string;
  type: "Plus" | "Core";
  payment_methods?: string[];
  expiration?: string;
}

interface IState {
  isFetching: boolean;
  venue: any;
  accounts: IAccount[];
  errorId: string | null;
}
interface IProps extends WithAppAuthTypes {
  onChangeLanguage: () => any;
  locale: "fi" | "en";
}

export class Pay extends Component<IProps, IState> {
  state = {
    isFetching: false,
    venue: {},
    accounts: [],
    errorId: null,
  };
  async componentDidUpdate(prevProps: IProps) {
    const { accessToken } = this.props;

    if (accessToken && prevProps.accessToken !== accessToken) {
      await this.fetchData(accessToken);
    }
  }

  fetchData = async (accessToken: string) => {
    try {
      await this.fetchVenue();
      await this.fetchAccounts(accessToken);

      this.setState({ isFetching: false });
    } catch (e: any) {
      const errorId = getApiErrorTranslationId(e?.error);

      this.setState({ isFetching: false, errorId });
    }
  };

  fetchAccounts = async (accessToken: string | null) => {
    let errorId: IState['errorId'];

    const { data: accounts } = await getAccounts(accessToken);

    const promises = accounts.map(async (account: IAccount) => {

      if (account.balance !== 0) {
        try {
          const { data: accountExp } = await getExpirations(
            accessToken,
            account.id
          );
          const expirations = accountExp[0]?.expires_at;

          account.expiration =
            formatISO8601DateToFinnishLocaleDateString(expirations);
        } catch (e: any) {
          errorId = getApiErrorTranslationId(e?.error);
        }
      }
    });
    Promise.all(promises).then(() => {
      this.setState((prevState) => ({
        accounts: prevState.accounts.concat(accounts),
        errorId,
      }));
    });

  };

  fetchVenue = async () => {
    const { data: venue } = await getVenue(this.props.info.venue_id);
    this.setState({
      venue,
    });
  };

  render() {
    if (this.props.appAuthLoading) {
      return <Loader />;
    }
    const { hasWrongParams, hasError: hasAuthError } = this.props;
    const hasAnyError = hasWrongParams || hasAuthError || this.state.errorId;
    if (hasAnyError) {
      return (
        <ErrorMessage
          apiErrorId={
            this.state.errorId ? `ApiError.${this.state.errorId}` : null
          }
          hasInternalError={!hasWrongParams}
        >
          <AppHeader
            onChangeLanguage={this.props.onChangeLanguage}
            value={this.props.locale}
            hasGenericError={true}
          />
        </ErrorMessage>
      );
    }
    const { cancel_url, benefit = "exercise" } = this.props.info;
    const backgroundStyles = { backgroundImage: `url(${benefit}@3x.png)` };
    return (
      <>
        <Background style={backgroundStyles} />
        <AppHeader
          onChangeLanguage={this.props.onChangeLanguage}
          value={this.props.locale}
        />
        <AppContent
          venue={this.state.venue}
          info={this.props.info}
          accounts={this.state.accounts}
          accessToken={this.props.accessToken}
          locale={this.props.locale}
        />
        <AppFooter cancelUrl={cancel_url} />
      </>
    );
  }
}

export default withAppAuth(Pay);
