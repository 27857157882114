import React from "react";
import {
  RedirectRequestHandler,
  AuthorizationServiceConfiguration,
  LocalStorageBackend,
  DefaultCrypto,
  AuthorizationRequest,
  AuthorizationNotifier
} from "@openid/appauth";
import uuidv4 from "uuid/v4";

import { storage } from "../utils/storage";
import NoHashQueryStringUtils from "../utils/NoHashQueryStringUtils";
import {
  getConfig,
  makeAuthRequest,
  makeTokenRequest
} from "../utils/appAuthHelpers";
import { checkoutInfoParams, hasCheckoutURLParams } from "../utils/url";

export type BenefitType =
  | "commuter"
  | "exercise"
  | "massage"
  | "culture"
  | "lunch";

export type InfoType = {
  benefit: BenefitType;
  venue_id: string;
  amount: string;
  product_name: string;
  success_url: string;
  cancel_url: string;
  iat: number;
  product_image_url?: string;
  product_description?: string;
  timeout?: string;
  nonce?: string;
  stripe_account?: string;
  max_benefit_amount?: string;
  idempotency_id?: string;
};

export interface WithAppAuthTypes {
  accessToken: string | null | undefined;
  appAuthLoading: boolean;
  info: InfoType;
  hasWrongParams: boolean;
  hasError: boolean;
}

const withAppAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return class withAppAuth extends React.Component<unknown, WithAppAuthTypes> {
    authorizationHandler: RedirectRequestHandler;
    request: AuthorizationRequest | null;
    code: string;
    notifier: AuthorizationNotifier;
    storageKey: string;

    constructor(props: unknown) {
      super(props);
      this.request = null;
      this.code = "";
      this.storageKey = "";
      this.notifier = new AuthorizationNotifier();
      this.authorizationHandler = new RedirectRequestHandler(
        new LocalStorageBackend(),
        new NoHashQueryStringUtils(),
        window.location,
        new DefaultCrypto()
      );
      this.authorizationHandler.setAuthorizationNotifier(this.notifier);
      this.notifier.setAuthorizationListener((request, response, error) => {
        if (response) {
          this.request = request;
          this.code = response.code;
          this.storageKey = response.state;
        }
        if (error) {
          this.setState({ hasError: true, appAuthLoading: false });
        }
      });
    }

    state = {
      accessToken: "",
      appAuthLoading: true,
      hasWrongParams: false,
      hasError: false,
      info: {} as InfoType
    };

    async componentDidMount() {
      try {
        await this.checkForAuthorizationResponse();
        const config = await getConfig();
        await this.handleBootup(this.code, config);
      } catch (e) {
        this.setState({ hasError: true, appAuthLoading: false });
      }
    }

    handleBootup = async (
      code: string,
      config: AuthorizationServiceConfiguration
    ) => {
      if (!code) {
        this.checkParams(config);
      } else {
        this.getCheckoutInfoFromLocal();
        const accessToken = await makeTokenRequest(
          config,
          this.code,
          this.request
        );
        this.setState({ accessToken, appAuthLoading: false });
      }
    };

    getCheckoutInfoFromLocal = () => {
      const infoString = storage.getItem(this.storageKey) || "{}";
      const info = JSON.parse(infoString);
      this.setState({ info });
      storage.removeItem(this.storageKey);
    };

    checkParams = (config: AuthorizationServiceConfiguration) => {
      const uuid = uuidv4();
      if (hasCheckoutURLParams()) {
        const checkoutInfo = checkoutInfoParams as InfoType
        // if true, save params to local storage and run makeAuthRequest to get code
        const iat =
          checkoutInfo.timeout && parseInt(checkoutInfo.timeout, 10) > 0
            ? Date.now() + parseInt(checkoutInfo.timeout, 10) * 1000
            : 0;
        storage.setItem(uuid, JSON.stringify({ ...checkoutInfo, iat }));
        makeAuthRequest(config, uuid);
      } else {
        // if false, set necessary states
        this.setState({ appAuthLoading: false, hasWrongParams: true });
      }
    };

    checkForAuthorizationResponse = async () => {
      await this.authorizationHandler.completeAuthorizationRequestIfPossible();
    };
    render() {
      return <WrappedComponent {...this.state as any} />;
    }
  };
};

export default withAppAuth;
