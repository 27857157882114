import React from "react";
import { InfoType } from "../../withAppAuth";
import {
  VenueName,
  VenueInfo,
  Detail,
  Price,
  Description,
  VenueWrapper,
  ProductImage,
} from "../../StyledComponents";
import formatCurrency from "../../../utils/formatCurrency";

interface IProps {
  venue: any;
  info: InfoType;
}

const VenueSection: React.FC<IProps> = ({ venue, info }) => {
  const { product_name, product_description, amount, product_image_url } = info;
  const venueName = venue ? venue.name : "";
  return (
    <VenueWrapper>
      <VenueName>{venueName}</VenueName>
      <VenueInfo>
        {product_image_url ? <ProductImage src={product_image_url} /> : null}
        <div>
          <Detail>{product_name}</Detail>
          <Price>{formatCurrency(parseInt(amount))}€</Price>
          <Description>{product_description}</Description>
        </div>
      </VenueInfo>
    </VenueWrapper>
  );
};

export default VenueSection;
