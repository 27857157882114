import React from "react";
import { Typography, Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Venue } from "../../../types/venue";
import VenueItem from "../VenueItem";
import { FormattedMessage } from "react-intl";
import { isAcceptingOnlyQrPayments } from "../../../utils/benefit";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  searchResultPlaceholderText: {
    color: theme.palette.text.secondary,
  },
  loadMoreContainer: {
    display: "flex",
    flexDirection: "column",
  },
  loadMoreButton: {
    marginBottom: theme.spacing(2),
    alignSelf: "center",
  },
  venueItemContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

type VenueListProps = {
  venues: Venue[];
  query: string;
  venueFetched: boolean;
  loading: boolean;
  navigateToPaymentSelect: (venue: Venue) => void;
  handleLoadMore?: () => void;
};

export default function VenueList(props: Readonly<VenueListProps>) {
  const {
    venues,
    query,
    navigateToPaymentSelect,
    venueFetched,
    handleLoadMore,
    loading,
  } = props;
  const { classes } = useStyles();

  return (
    <>
      {venueFetched ? (
        <>
          <Box mx={{ sm: 6, md: 10 }}>
            {venues.length === 0 && !loading && (
              <Typography variant="h3" gutterBottom align="left">
                <FormattedMessage id="VenueSearch.noResults" />
              </Typography>
            )}
            {query !== "" && venues.length > 0 && (
              <>
                <Typography variant="h3" gutterBottom align="left">
                  <FormattedMessage
                    id="VenueSearch.searchQuery"
                    values={{
                      query: `${query}`,
                    }}
                  />
                </Typography>
              </>
            )}
          </Box>
          {venues.length ? (
            <Box
              component="ul"
              bgcolor="background.paper"
              p={{ sm: 6, md: 6 }}
              mx={{ sm: 0, md: 10 }}
              my={0}
            >
              {venues.map((venue) => (
                <Box
                  key={venue.id}
                  mt={2}
                  className={classes.venueItemContainer}
                >
                  <VenueItem
                    venue={venue}
                    isQrOnly={isAcceptingOnlyQrPayments(venue)}
                    showPaymentButton={true}
                    navigateToPaymentSelect={navigateToPaymentSelect}
                  />
                </Box>
              ))}
            </Box>
          ) : null}
          {venues.length > 0 && (
            <Box
              className={classes.loadMoreContainer}
              bgcolor="background.paper"
              mx={{ sm: 0, md: 10 }}
              pt={2}
            >
              <Typography variant="body2" align="center">
                <FormattedMessage
                  id="VenueSearch.numberOfVenues"
                  values={{ number: venues.length }}
                />
              </Typography>
              <Box mt={2} />
              <Button
                className={classes.loadMoreButton}
                onClick={handleLoadMore}
              >
                <FormattedMessage id="VenueSearch.loadMore" />
              </Button>
              <Box mt={8} />
            </Box>
          )}
        </>
      ) : (
        <Grid>
          <Typography
            variant="h3"
            className={classes.searchResultPlaceholderText}
          >
            <FormattedMessage id="VenueSearch.searchResultPlaceholder1" />
          </Typography>
          <Typography
            variant="h3"
            className={classes.searchResultPlaceholderText}
          >
            <FormattedMessage id="VenueSearch.searchResultPlaceholder2" />
          </Typography>
          <Typography
            variant="h3"
            className={classes.searchResultPlaceholderText}
          >
            <FormattedMessage id="VenueSearch.searchResultPlaceholder3" />
          </Typography>
          <Box mt={30} />
        </Grid>
      )}
    </>
  );
}
