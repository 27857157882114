import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ExpandMore } from "@mui/icons-material";
import {
  AccordionName,
  PaymentMethod,
} from "../../../containers/PaymentSelect";
import { GiftcardAccount } from "../../../types/account";
import { centsToEuros } from "../../../utils/amount/toEuros";
import { useIntl } from "react-intl";
import { Benefit } from "../../../types/venue";
import { benefitToTranslationKey } from "../../../utils/benefit";
import { makeStyles } from "tss-react/mui";
import { trackEvent } from "../../../services/analytics/analytics";

const useStyles = makeStyles()((theme) => ({
  paymentTypeContainer: {
    display: "flex",
    flexDirection: "column",
  },
  paymentMethodType: {
    border: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
  emailInputContainer: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
  },
  acccordionDetails: {
    display: "flex",
  },
}));

type PaymentMethodContainerProps = {
  paymentMethod: PaymentMethod | null;
  setPaymentMethod: (method: PaymentMethod) => void;
  expanded: boolean;
  handleAccordionChange: (
    panel: AccordionName
  ) => (event: React.ChangeEvent<object>, newExpanded: boolean) => void;
  setExpanded: (panel: boolean) => void;
  isGiftcardPaymentDisabled: boolean;
  renderGiftcardInputContainer: React.ReactNode;
  renderBenefitSelection: React.ReactNode;
  isValidGiftcard: boolean;
  giftcardAccount: GiftcardAccount | null;
  email: string;
  setEmail: (value: string) => void;
  selectedBenefit?: Benefit;
  paymentMethodValidationError: string | null;
  setEmailValidationError: (value: string | null) => void;
};

export default function PaymentMethodContainer(
  props: Readonly<PaymentMethodContainerProps>
) {
  const {
    paymentMethod,
    setPaymentMethod,
    expanded,
    handleAccordionChange,
    renderGiftcardInputContainer,
    renderBenefitSelection,
    isValidGiftcard,
    giftcardAccount,
    email,
    setEmail,
    selectedBenefit,
    paymentMethodValidationError,
    setEmailValidationError,
    isGiftcardPaymentDisabled,
  } = props;

  const { classes } = useStyles();
  const intl = useIntl();

  const isBenefitPaymentSelected = paymentMethod === "benefit";
  const selectedPaymentMethod = !isGiftcardPaymentDisabled
    ? paymentMethod
    : "benefit";

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPaymentMethod = event.target.value as PaymentMethod;
    setPaymentMethod(newPaymentMethod);
    trackEvent({
      name: "add_payment_info", 
      params: {
        payment_type: newPaymentMethod,
    }});
  };

  const benefitTypeText = selectedBenefit
    ? intl.formatMessage(
        { id: "PaymentMethod.benefitType" },
        {
          benefit: intl.formatMessage({
            id: `benefits.${benefitToTranslationKey(selectedBenefit)}`,
          }),
        }
      )
    : intl.formatMessage({ id: "PaymentMethod.benefit" });

  const handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setEmail(value || "");
    setEmailValidationError(null);
  };

  return (
    <Box>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange("paymentMethod")}
        square={false}
      >
        <AccordionSummary
          disableRipple
          expandIcon={<ExpandMore />}
          aria-controls="paymentMethod"
          id="paymentMethod"
        >
          <Box>
            <Typography
              variant="body1"
              color={paymentMethodValidationError ? "error" : "initial"}
            >
              {intl.formatMessage({ id: "PaymentMethod.selectPaymentMethod" })}
            </Typography>
            <Box mt={2} />

            {isBenefitPaymentSelected && selectedBenefit && (
              <Typography variant="h5">{benefitTypeText}</Typography>
            )}
            {isValidGiftcard && (
              <Box>
                <Typography variant="h5">{`${intl.formatMessage({
                  id: `giftcards.${giftcardAccount?.name}`,
                })} `}</Typography>
                <Typography variant="h6">{`${intl.formatMessage({
                  id: "GiftcardInput.giftcardRemainingBalance",
                })} ${centsToEuros(giftcardAccount?.balance)}`}</Typography>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.acccordionDetails}>
          <Box className={classes.paymentTypeContainer}>
            <FormControl>
              <RadioGroup
                aria-labelledby="controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                onChange={handleChange}
                value={selectedPaymentMethod}
              >
                <FormControlLabel
                  className={classes.paymentMethodType}
                  value="benefit"
                  control={<Radio color="primary" />}
                  disabled={isGiftcardPaymentDisabled ? true : false}
                  label={intl.formatMessage({
                    id: "PaymentMethod.beneficiaryPayment",
                  })}
                />
                <Box mt={2} />
                {!isGiftcardPaymentDisabled && (
                  <FormControlLabel
                    className={classes.paymentMethodType}
                    value="giftcard"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      id: "PaymentMethod.giftcardPayment",
                    })}
                  />
                )}
              </RadioGroup>
            </FormControl>
            {paymentMethod === "giftcard" && renderGiftcardInputContainer}
            {paymentMethod === "benefit" && renderBenefitSelection}
          </Box>
        </AccordionDetails>
        <Box mt={2} />
        <Typography variant="body1">
          {intl.formatMessage({
            id: "GiftcardInput.giftcardInputDisclaimer",
          })}
        </Typography>
        <Box mt={2} />
      </Accordion>

      {isValidGiftcard && (
        <Grid className={classes.emailInputContainer}>
          <Typography variant="subtitle2" color="textSecondary">
            {intl.formatMessage({
              id: "GiftcardInput.email",
            })}
          </Typography>
          <Box mt={2} />
          <OutlinedInput
            placeholder="nimi@email.fi"
            id="outlined-basic"
            value={email}
            onChange={handleEmailInputChange}
          />
          <Box mt={2} />
          <Typography variant="body1">
            {intl.formatMessage({
              id: "GiftcardInput.giftcardReceiptInfo",
            })}
          </Typography>
        </Grid>
      )}
    </Box>
  );
}
