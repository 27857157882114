import React from "react";
import { FormattedMessage } from "react-intl";
import {
  MainAppFooter,
  ArrowLeft,
  FooterList,
  FooterListItem,
} from "../StyledComponents";
import leftArrow from "../../assets/arrow-left.svg";

export default function AppFooter({ cancelUrl }: { cancelUrl: string }) {
  function handleClick() {
    window.location.replace(cancelUrl);
  }

  return (
    <MainAppFooter>
      <ArrowLeft src={leftArrow} onClick={handleClick} />
      <FooterList>
        <FooterListItem onClick={handleClick}>
          <FormattedMessage
            id="AppFooter.cancelText"
            defaultMessage="Peruuta"
          />
        </FooterListItem>
        <FooterListItem>
          <a href="https://www.smartum.fi/" target="_blank" rel="noreferrer">
            smartum.fi
          </a>
        </FooterListItem>
        <FooterListItem>
          <a
            href="https://support.smartum.fi/"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage
              id="AppFooter.helpCenterText"
              defaultMessage="Asiakastuki"
            />
          </a>
        </FooterListItem>
      </FooterList>
    </MainAppFooter>
  );
}
