import React, { PureComponent } from "react";
import { TimeRemaining, TimeWrapper } from "../../StyledComponents";
import { FormattedMessage } from "react-intl";

function convertTime(time: number) {
  return `${Math.floor(time / 60)}:${
    time % 60 < 10 ? "0" + (time % 60) : time % 60
  }`;
}

export default class Countdown extends PureComponent<
  { iat: number; handleTimeout: () => any; render: (func : any) => any },
  { timeRemaining: number; didCountdownStop: boolean }
> {
  interval: number;
  constructor(props: any) {
    super(props);
    this.interval = 0;

    this.clearCountDown = this.clearCountDown.bind(this);
  }
  state = {
    timeRemaining: Math.floor((this.props.iat - Date.now()) / 1000),
    didCountdownStop: false
  };
  componentDidMount() {
    if (this.props.iat) {
      this.interval = window.setInterval(() => {
        if (this.state.timeRemaining > 0) {
          this.setState((prevState) => ({
            timeRemaining: prevState.timeRemaining - 1
          }));
        } else {
          this.clearCountDown();
          return false;
        }
      }, 1000);
    }
  }

  componentDidUpdate() {
    if (this.state.timeRemaining <= 0 && !this.state.didCountdownStop) {
      this.clearCountDown();
      this.props.handleTimeout();
    }
  }

  componentWillUnmount() {
    this.clearCountDown();
  }

  clearCountDown() {
    this.setState({ didCountdownStop: true });
    clearInterval(this.interval);
  }
  render() {
    return (
      <>
        {this.props.render({ clearCountDown: this.clearCountDown })}
        <TimeWrapper>
          <TimeRemaining>
            <FormattedMessage
              id="AppContent.timeRemaining"
              defaultMessage="10:00 aikaa jäljellä"
              values={{
                time: convertTime(this.state.timeRemaining)
              }}
            />
          </TimeRemaining>
        </TimeWrapper>
      </>
    );
  }
}
