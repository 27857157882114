import {
  RedirectRequestHandler,
  AuthorizationServiceConfiguration,
  LocalStorageBackend,
  DefaultCrypto,
  AuthorizationRequest,
  AuthorizationNotifier,
  BaseTokenRequestHandler,
  TokenRequest,
  StringMap,
  FetchRequestor
} from "@openid/appauth";
import { GRANT_TYPE } from "../constants";
import NoHashQueryStringUtils from "../utils/NoHashQueryStringUtils";
import config from "../config";

const {
  REACT_APP_ISSUER,
  REACT_APP_CLIENT_ID,
  REACT_APP_REDIRECT_URI
} = config;

export const getConfig = async () => {
    const configuration = await AuthorizationServiceConfiguration.fetchFromIssuer(
      REACT_APP_ISSUER,
      new FetchRequestor()
    );
    return configuration;
};

export const makeAuthRequest = (
  config: AuthorizationServiceConfiguration,
  uuid: string
) => {
  const notifier = new AuthorizationNotifier();

  const authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
    new DefaultCrypto()
  );

  authorizationHandler.setAuthorizationNotifier(notifier);
  const request = new AuthorizationRequest({
    client_id: REACT_APP_CLIENT_ID,
    redirect_uri: REACT_APP_REDIRECT_URI,
    scope: "openid create:payments read:accounts",
    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
    state: uuid,
    extras: { prompt: "consent", access_type: "offline", useNewAuth: '1' }
  });
    authorizationHandler.performAuthorizationRequest(config, request);
};

export const makeTokenRequest = async (
  config: AuthorizationServiceConfiguration,
  code: string,
  req: AuthorizationRequest | null
) => {
  const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
  let request: TokenRequest | null = null;
  let extras: StringMap | undefined = undefined;
  if (req?.internal) {
    extras = {};
    extras["code_verifier"] = req.internal["code_verifier"];
  }
  request = new TokenRequest({
    client_id: REACT_APP_CLIENT_ID,
    redirect_uri: REACT_APP_REDIRECT_URI,
    grant_type: GRANT_TYPE.AUTHORIZATION_CODE,
    code,
    refresh_token: undefined,
    extras
  });
    const response = await tokenHandler.performTokenRequest(config, request);
    return response.accessToken;
};
