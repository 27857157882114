import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import iconInstagram from "../../assets/instagram.svg";
import iconFacebook from "../../assets/facebook.svg";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    flexGrow: 1,
    margin: "auto",
    left: "0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  infoSection: {
    width: "100%",
    padding: theme.spacing(6, 0),
    backgroundColor: theme.palette.background.paper,
  },
  infoSectionContainer: {
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  footerSection: {
    width: "100%",
    backgroundColor: "#000",
    color: "#fff",
    padding: theme.spacing(6, 0),
  },
  section: {
    margin: theme.spacing(2, 6),
    textAlign: "left",
  },
  sectionTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  infoText: {
    fontSize: "14px",
  },
  footerText: {
    // margin: 0,
    fontSize: "0.875rem",
    margin: theme.spacing(1, 3),
    // marginRight: theme.spacing(8),
  },
  footerIcon: {
    margin: theme.spacing(1, 3),
  },
  linkText: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  linkText2: {
    color: "#fff",
    textDecoration: "underline",
  },
  footerRow: {
    display: "flex",
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
}));

export default function VenueSearchFooter() {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.footerContainer}>
      <Box className={classes.infoSection}>
        <Grid container className={classes.infoSectionContainer}>
          <Grid size={{ xs: 12, sm: "auto" }} className={classes.section}>
            <Typography className={classes.sectionTitle}>
              <FormattedMessage id="VenueSearchFooter.companyInfo.companyName" />
            </Typography>
            <Typography className={classes.infoText}>
              <FormattedMessage id="VenueSearchFooter.companyInfo.companyAddress" />
            </Typography>
            <Typography className={classes.infoText}>
              <FormattedMessage id="VenueSearchFooter.companyInfo.companyPostalCode" />
            </Typography>
            <Typography className={classes.infoText}>
              <FormattedMessage id="VenueSearchFooter.companyInfo.companyIdentifier" />
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, sm: "auto" }} className={classes.section}>
            <Typography className={classes.sectionTitle}>
              <FormattedMessage id="VenueSearchFooter.products.sectionTitle" />
            </Typography>
            <Typography className={classes.infoText}>
              <a
                className={classes.linkText}
                href={intl.formatMessage({
                  id: "VenueSearchFooter.products.purchaseGiftcardLink",
                })}
              >
                <FormattedMessage id="VenueSearchFooter.products.purchaseGiftcard" />
              </a>
            </Typography>
            <Typography className={classes.infoText}>
              <a
                className={classes.linkText}
                href={intl.formatMessage({
                  id: "VenueSearchFooter.products.useGiftcardLink",
                })}
              >
                <FormattedMessage id="VenueSearchFooter.products.useGiftcard" />
              </a>
            </Typography>
            <Typography className={classes.infoText}>
              <a
                className={classes.linkText}
                href={intl.formatMessage({
                  id: "VenueSearchFooter.products.employeeBenefitsLink",
                })}
              >
                <FormattedMessage id="VenueSearchFooter.products.employeeBenefits" />
              </a>
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, sm: "auto" }} className={classes.section}>
            <Typography className={classes.sectionTitle}>
              <FormattedMessage id="VenueSearchFooter.help.heading" />
            </Typography>
            <Typography className={classes.infoText}>
              <a
                className={classes.linkText}
                href={intl.formatMessage({
                  id: "VenueSearchFooter.help.faqLink",
                })}
              >
                <FormattedMessage id="VenueSearchFooter.help.faq" />
              </a>
            </Typography>
            <Typography className={classes.infoText}>
              <a
                className={classes.linkText}
                href={intl.formatMessage({
                  id: "VenueSearchFooter.help.helpCenterLink",
                })}
              >
                <FormattedMessage id="VenueSearchFooter.help.helpCenter" />
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.footerSection}>
        <Grid container direction="column" alignItems="center">
          <Grid className={classes.footerRow}>
            <Typography className={classes.footerText}>
              <FormattedMessage id="VenueSearchFooter.footer.copyrightText" />
            </Typography>
            <Typography className={classes.footerText}>
              <FormattedMessage id="VenueSearchFooter.footer.rightsReserved" />
            </Typography>
          </Grid>

          <Grid className={classes.footerRow}>
            <Typography className={classes.footerText}>
              <a
                className={classes.linkText2}
                href={intl.formatMessage({
                  id: "VenueSearchFooter.footer.privacyPolicyLink",
                })}
              >
                <FormattedMessage id="VenueSearchFooter.footer.privacyPolicy" />
              </a>
            </Typography>
            <Typography className={classes.footerText}>
              <a
                className={classes.linkText2}
                href={intl.formatMessage({
                  id: "VenueSearchFooter.footer.cookiesLink",
                })}
              >
                <FormattedMessage id="VenueSearchFooter.footer.cookies" />
              </a>
            </Typography>
          </Grid>

          <Grid className={classes.footerRow}>
            <a
              className={classes.footerIcon}
              href={intl.formatMessage({
                id: "VenueSearchFooter.footer.facebook",
              })}
            >
              <img src={iconFacebook} alt="Facebook" />
            </a>
            <a
              className={classes.footerIcon}
              href={intl.formatMessage({
                id: "VenueSearchFooter.footer.instagram",
              })}
            >
              <img src={iconInstagram} alt="Instagram" />
            </a>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
