export const validateValue = (
  isValid: boolean,
  setterFn: (val: string | null) => void,
  error: string
) => {
  if (!isValid) {
    setterFn(error);
  } else {
    setterFn(null);
  }
};

export const amountPattern = /^[0-9]*([.,][0-9]{0,2})?$/

export const isValidAmount = (string: string) => {
  return amountPattern.test(string)
}