import { ApiResponseAccount, GiftcardAccount } from "../../types/account";
import { getGiftcardType } from "../benefit";

export const parseToGiftcardAccount = (account: ApiResponseAccount, token: string): GiftcardAccount => {
  const expiresAt = new Date(account.expirations[0].expires_at)
  return {
    id: account.id,
    name: getGiftcardType(account.benefits),
    benefits: account.benefits,
    expiresAt,
    balance: account.balance,
    token
  };
};
