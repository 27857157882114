import "react-app-polyfill/ie11";
import "core-js";
import "./polyfills/intl.js";
import React from "react";
// eslint-disable-next-line react/no-deprecated
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/fonts.css";
import App from "./App";

const domNode = document.getElementById("root");
const root = ReactDOM.createRoot(domNode!);

root.render(<App />);
