const hostname = window?.location?.hostname || "";

const getEnvironment = () => {
  if (["checkout.smartum.fi", "pay.smartum.fi"].includes(hostname)) {
    return "production";
  } 
};

const additionalParameters = {
  environment: getEnvironment(),
};

export const trackEvent = ({ name, params }) => {
  window.gtag('event', name, {
    ...params, ...additionalParameters
  });
};
