import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(() => ({
  continueButton: {
    maxWidth: "327px",
    width: "100%",
  },
}));

export default useStyles;
