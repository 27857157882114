import {
  benefitPriorityOrder,
  giftcardBenefitTypes,
  wellbeingGiftcard,
} from "../../constants";
import { GiftcardAccount } from "../../types/account";
import {
  ApiResponseVenue,
  Benefit,
  DisplaydBenefit,
  GiftcardBenefitType,
  GiftcardType,
  Venue,
} from "../../types/venue";

/**
 * Type guard function to check if a string is a GiftcardBenefitType.
 *
 * @param value - The string to check.
 * @returns True if the value is not null and is included in the giftcardBenefitTypes array, false otherwise.
 */
export const isGiftcardBenefitType = (
  value: string | null
): value is GiftcardBenefitType => {
  return (
    value !== null &&
    giftcardBenefitTypes.includes(value as GiftcardBenefitType)
  );
};

export const benefitToTranslationKey = (benefit: Benefit): DisplaydBenefit => {
  return benefit === "culture" ? "exerciseAndCulture" : benefit;
};

export const getGiftcardType = (
  benefits: GiftcardBenefitType[]
): GiftcardType | "" => {
  if (giftcardBenefitTypes.every((benefit) => benefits.includes(benefit))) {
    return "generic";
  } else if (wellbeingGiftcard.every((benefit) => benefits.includes(benefit))) {
    return "wellbeing";
  } else if (
    benefits.length === 1 &&
    (benefits[0] === "exercise" || benefits[0] === "culture")
  ) {
    return benefits[0];
  }
  return "";
};

export const getBenefitList = (benefits: Benefit[]): DisplaydBenefit[] => {
  const parsedBenefits = benefits.map((b) => benefitToTranslationKey(b));
  if (benefits.includes("exercise") && !benefits.includes("culture")) {
    parsedBenefits.push("exerciseAndCulture");
  }
  return parsedBenefits;
};

export const getBenefitAndGiftcardList = (
  venueBenefits?: Benefit[] | null
): { benefits: string[]; giftcards: GiftcardType[] } | null => {
  if (!venueBenefits) {
    return null;
  }
  const giftcards: GiftcardType[] = [];

  if (giftcardBenefitTypes.some((benefit) => venueBenefits.includes(benefit))) {
    giftcards.push("generic");
  }
  if (wellbeingGiftcard.some((benefit) => venueBenefits.includes(benefit))) {
    giftcards.push("wellbeing");
  }
  const venueGiftcardBenefits = venueBenefits.filter(
    (b) => b === "culture" || b === "exercise"
  );
  giftcards.push(...(venueGiftcardBenefits as GiftcardType[]));

  return {
    benefits: getBenefitList(venueBenefits),
    giftcards,
  };
};

export const isAcceptingOnlyQrPayments = (venue: Venue): boolean => {
  return (
    venue.accepted_payment_methods?.length === 1 &&
    venue.accepted_payment_methods[0] === "qr"
  );
};

export const venueHasBenefit = (
  venue: ApiResponseVenue | null,
  benefit: Benefit
): boolean => {
  return venue?.accepted_benefits?.includes(benefit) ?? false;
};

export const getPriorityBenefit = (
  venue: Venue,
  giftcardAccount: GiftcardAccount
) => {
  const selectedBenefit = benefitPriorityOrder.find(
    (benefit) =>
      venue.accepted_benefits.includes(benefit) &&
      giftcardAccount.benefits.includes(benefit as GiftcardBenefitType)
  ) as GiftcardBenefitType;
  return selectedBenefit;
};
