import React, { useMemo, useState } from "react";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Venue } from "../../../types/venue";
import { FormattedMessage, useIntl } from "react-intl";
import { ExpandMore } from "@mui/icons-material";
import VenueMap from "../../VenueMap";
import iconCommuter from "../../../assets/venue-type-icons/iconCommuter.png";
import iconCulture from "../../../assets/venue-type-icons/iconCulture.png";
import iconExercise from "../../../assets/venue-type-icons/iconExercise.png";
import iconHealth from "../../../assets/venue-type-icons/iconHealth.png";
import iconLunch from "../../../assets/venue-type-icons/iconLunch.png";
import iconMassage from "../../../assets/venue-type-icons/iconMassage.png";
import iconMobile from "../../../assets/payment-type-icons/mobile.svg";
import iconQr from "../../../assets/payment-type-icons/qr.svg";
import iconSms from "../../../assets/payment-type-icons/sms.svg";
import iconVisa from "../../../assets/payment-type-icons/visa.svg";
import iconVoucher from "../../../assets/payment-type-icons/voucher.svg";
import iconGiftcard from "../../../assets/payment-type-icons/giftcard.svg";
import {
  venueAcceptsGiftcardPayment,
  venueAcceptsMobilePayment,
  venuePaymentMethods,
} from "../../../utils/venue";
import { SMS_PAYMENT_PHONE_NUMBER } from "../../../constants";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  venueItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  venueIcon: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(4),
  },
  venueIconImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  venueLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    marginBottom: theme.spacing(1),
  },
  venueLinkText: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    marginBottom: theme.spacing(1),
  },
  paymentMethods: {
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  paymentMethodIcon: {
    width: 24,
    height: 24,
    marginLeft: theme.spacing(2),
  },
  acceptsBenefit: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  mapButton: {
    color: theme.palette.text.primary,
    border: "none",
    backgroundColor: "unset",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  paymentButton: {
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

type VenueItemProps = {
  venue: Venue;
  isQrOnly: boolean;
  showPaymentButton?: boolean;
  navigateToPaymentSelect?: (venue: Venue) => void;
};

const venueIcons = {
  commuter: iconCommuter,
  culture: iconCulture,
  exercise: iconExercise,
  health: iconHealth,
  lunch: iconLunch,
  massage: iconMassage,
};

const paymentMethodIcons = {
  mobile: iconMobile,
  qr: iconQr,
  sms: iconSms,
  visa: iconVisa,
  voucher: iconVoucher,
  giftcard: iconGiftcard,
};

export default function VenueItem(props: Readonly<VenueItemProps>) {
  const {
    venue,
    isQrOnly,
    showPaymentButton = false,
    navigateToPaymentSelect,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const { classes } = useStyles();
  const intl = useIntl();

  const venueIconsSrc = venue.accepted_benefits?.map(
    (benefit) => venueIcons[benefit]
  );

  const isPaymentVisible = useMemo(() => {
    return venueAcceptsMobilePayment(venue);
  }, [venue]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const venueAcceptedPaymentMethods = useMemo(() => {
    return venue.accepted_payment_methods.filter(
      (method) => !["qr", "visa"].includes(method)
    );
  }, [venue.accepted_payment_methods]);

  const showGiftcardIcon = venueAcceptsGiftcardPayment(venue);

  return (
    <Box>
      <Box className={classes.venueItemContainer}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {venueIconsSrc?.map((iconSrc, index) => (
            <Box className={classes.venueIcon} key={index}>
              <img
                src={iconSrc}
                alt={`benefit icon ${index}`}
                className={classes.venueIconImg}
              />
            </Box>
          ))}
          <Typography variant="h5">{venue.name}</Typography>
        </Grid>
        <Box mt={4} />
        <Typography variant="body1">{venue.description}</Typography>
        <Typography className={classes.venueLink} variant="body1">
          <a className={classes.venueLinkText} href={venue.website}>
            {venue.website}
          </a>
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {venue.address.line1}
          {venue.address.line2 && " " + venue.address.line2}
          {", "}
          {venue.address.postal_code} {venue.address.city}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {venue.phone}
        </Typography>

        {isQrOnly ? (
          <Typography className={classes.paymentMethods} variant="body1">
            {intl.formatMessage({ id: "VenueItem.onlinePayment" })}
          </Typography>
        ) : venueAcceptedPaymentMethods.length ? (
          <Box className={classes.paymentMethods}>
            <Typography variant="body1">
              {`${intl.formatMessage({ id: "VenueItem.paymentMethods" })}:`}
            </Typography>
            {venueAcceptedPaymentMethods.map((method) => {
              const paymentIconSrc = paymentMethodIcons[method];
              return (
                <Tooltip
                  title={
                    <Typography variant="body2">
                      <FormattedMessage
                        id={`VenueItem.paymentMethod.${method}`}
                        values={{
                          phoneNumber:
                            method === "sms" ? SMS_PAYMENT_PHONE_NUMBER : null,
                        }}
                      />
                    </Typography>
                  }
                  arrow
                  key={method}
                  placement="top-start"
                >
                  <Box display="flex">
                    <img
                      src={paymentIconSrc}
                      alt={`${method} icon`}
                      className={classes.paymentMethodIcon}
                    />
                    {method === "sms" && (
                      <>
                        <Box ml={1} />
                        <Typography variant="body2" component="span">
                          SMS {venue.shortcode}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Tooltip>
              );
            })}
            {showGiftcardIcon && (
              <Tooltip
                title={
                  <Typography variant="body2">
                    <FormattedMessage id={`VenueItem.paymentMethod.giftcard`} />
                  </Typography>
                }
                arrow
                key={"giftcard"}
                placement="top-start"
              >
                <Box display="flex">
                  <img
                    src={iconGiftcard}
                    alt="Giftcard icon"
                    className={classes.paymentMethodIcon}
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        ) : null}
        <Typography color="textSecondary">
          {intl.formatMessage({ id: "VenueItem.acceptsPayment" })}:{" "}
          {venuePaymentMethods(venue, intl).join(", ")}
        </Typography>

        <Box mt={6} />
        <Grid
          container
          sx={{ flexGrow: 1, width: "100%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            className={classes.mapButton}
            variant="outlined"
            endIcon={<ExpandMore />}
            onClick={handleClick}
          >
            <FormattedMessage id="VenueItem.showOnMap" />
          </Button>
          {showPaymentButton &&
            !isQrOnly &&
            navigateToPaymentSelect &&
            isPaymentVisible && (
              <Button
                className={classes.paymentButton}
                onClick={() => navigateToPaymentSelect(venue)}
              >
                <FormattedMessage id="VenueItem.makePayment" />
              </Button>
            )}
        </Grid>
        <Box mt={6} />
        {isExpanded && (
          <VenueMap
            longitude={venue.address.geojson.coordinates[0]}
            latitude={venue.address.geojson.coordinates[1]}
          />
        )}
      </Box>
    </Box>
  );
}
